import { auth, provider } from "../../config/firebase-config";
import { signInWithPopup } from "firebase/auth";
import { useNavigate, Navigate } from "react-router-dom";
import { useGetUserInfo } from "../../hooks/useGerUserinfo";

export const Auth = () => {
  const navigate = useNavigate();
  const { isAuth } = useGetUserInfo();

  const signInWithGoogle = async () => {
    const results = await signInWithPopup(auth, provider);
    const authInfo = {
      userID: results.user.uid,
      name: results.user.displayName,
      profilePhoto: results.user.photoURL,
      isAuth: true,
    };
    localStorage.setItem("auth", JSON.stringify(authInfo));
    navigate("/expense-tracker");
  };

  if (isAuth) {
    return <Navigate to="/expense-tracker" />;
  }

  return (
    <div id="content">
      <div id="info">
        <h1>Expense Tracker</h1>
        <p>Track your income and expenses easily!</p>
      </div>
      <div id="login-page">
        <p>Sign In With Google to Continue</p>
        <button id="login-btn" onClick={signInWithGoogle}>
          {" "}
          Sign In
        </button>
      </div>
    </div>
  );
};
