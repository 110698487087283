// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiz0ZoA8kyMBDnqjU4o2jGaJk15G_R0h4",
  authDomain: "expense-f0acf.firebaseapp.com",
  projectId: "expense-f0acf",
  storageBucket: "expense-f0acf.appspot.com",
  messagingSenderId: "269013231534",
  appId: "1:269013231534:web:d22a0e994ac88533e20a3f",
  measurementId: "G-D5ZKP2698D",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const db = getFirestore(app);
